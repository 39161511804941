import React from 'react'
import * as styles from './styles.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import BgLink from 'components/BgLink'
import Inview from 'components/Inview'

const TeamList = ({ teamMembers, isHome }) => {
  return (
    <div className={`${styles.teamList} ${isHome ? styles.isHome : ''} `}>
      {teamMembers.map((item, i) => (
        <TeamMember key={i} item={item} />
      ))}
    </div>
  )
}

export default TeamList

const TeamMember = ({ item }) => {
  const { locale } = useLocalization()
  return (
    <div className={styles.teamMember}>
      <Inview>
        <div className='fade-in-up'>
          <h3>{item.name}</h3>
          <h4>{item.jobTitle}</h4>
          <div className={styles.link}>
            {item.slug && (
              <BgLink
                to={`/team/${item.slug}/`}
                text={`${tr('BIO', locale)} »`}
                isLineLink={true}
              />
            )}
          </div>
        </div>
      </Inview>
    </div>
  )
}
