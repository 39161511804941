import React from 'react'

import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

import * as styles from './styles.module.scss'

const Component = ({ items }) => {
  return (
    <div className={styles.logoGrid}>
      {items.map((item, i) => (
        <Logo key={i} image={item.logo} />
      ))}
    </div>
  )
}
export default Component

const Logo = ({ image }) => {
  return (
    <div className={styles.logo}>
      <Inview>
        <div className={`${styles.imageWrap} fade-in-up `}>
          <ImageWrap image={image} aspectRatio={1} />
        </div>
      </Inview>
    </div>
  )
}
