import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './index.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import LogoGrid from 'components/LogoGrid'
import NewsLink from 'components/NewsLink'
import Seo from 'components/Seo'
import TextImage from 'components/TextImage'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`].nodes[0]
  const teamMembers = data[`teamPage_${locale}`].nodes[0].teamMembers
  const allNewsPost = data[`allNewsPost_${locale}`].nodes
  const portfolioCompanies = page.portfolioCompanies

  const aboutText = renderRichText(page.aboutText)
  const teamText = renderRichText(page.teamText)
  const portfolioText = renderRichText(page.portfolioText)
  const newsText = renderRichText(page.newsText)

  let seo = {
    en: {
      title: 'Life Sciences Venture Capital Firm in China',
    },
    zh: {
      title: '中国生命科学风险投资公司',
    },
  }
  seo = seo[locale]
  return (
    <Layout>
      <div>
        <Seo
          pageTitle={seo.title}
          // description={tr('SEO_DESCRIPTION', locale)}
          // keywords={tr('SEO_KEYWORDS', locale)}
        />
        <Banner images={page.slideshow} addClass='banner-home' />

        {/* ABOUT */}
        <div className='wrapper pad-sides'>
          <div className='inner'>
            <TextImage
              text={aboutText}
              image={page.aboutImage}
              link={{ to: '/about/' }}
              isContain={true}
              aspectRatio={1}
            />
          </div>
        </div>

        {/* TEAM */}
        <div className='wrapper pad-sides bg-dark'>
          <div className='inner'>
            <TextImage
              text={teamText}
              image={page.teamImage}
              isReversed={true}
              link={{ to: '/team/' }}
              isContain={true}
              aspectRatio={1.2}
              teamMembers={teamMembers}
            />
          </div>
        </div>

        {/* PORTFOLIO */}
        <div className={styles.dealsWrap}>
          <div className={`${styles.dealsWrapWrapper} wrapper pad-sides`}>
            <div className='inner'>
              <Inview>
                <div className='fade-in-up '>{portfolioText}</div>
              </Inview>
              <LogoGrid items={portfolioCompanies} />
              <Inview>
                <div className={`${styles.linkWrap} fade-in-up`}>
                  <BgLink
                    to='/portfolio/'
                    text={`${tr('READ_MORE', locale)} »`}
                    isCentered={true}
                  />
                </div>
              </Inview>
            </div>
          </div>
        </div>

        {/* NEWS */}
        <div className={styles.newsWrap}>
          <div
            className={`${styles.newsWrapWrapper} wrapper pad-sides bg-dark`}
          >
            <div className='inner'>
              <Inview>
                <div className='fade-in-up'>{newsText}</div>
              </Inview>
              <div className={styles.newsWrapInner}>
                <div className={styles.text}>
                  {allNewsPost.map((post, i) => (
                    <NewsLink key={i} post={post} />
                  ))}
                </div>
              </div>
              <div className={styles.linkWrap}>
                <BgLink
                  to='/news/'
                  text={`${tr('ALL_NEWS', locale)} »`}
                  isCentered={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: allContentfulHomePage(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        slideshow {
          ...GatsbyImage
        }
        aboutText {
          raw
        }
        aboutImage {
          ...GatsbyImage
        }
        teamText {
          raw
        }
        teamImage {
          ...GatsbyImage
        }
        portfolioText {
          raw
        }
        portfolioCompanies {
          logo {
            ...GatsbyImage
          }
        }
        newsText {
          raw
        }
      }
    }
    allNewsPost_en: allContentfulNewsPost(
      sort: { fields: postDate, order: DESC }
      limit: 3
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        slug
        title
        postDate(formatString: "YYYY-M-DD")
      }
    }
    teamPage_en: allContentfulTeamPage(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        teamMembers {
          name
          jobTitle
        }
      }
    }
    page_zh: allContentfulHomePage(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        slideshow {
          ...GatsbyImage
        }
        aboutText {
          raw
        }
        aboutImage {
          ...GatsbyImage
        }
        teamText {
          raw
        }
        teamImage {
          ...GatsbyImage
        }
        portfolioText {
          raw
        }
        portfolioCompanies {
          logo {
            ...GatsbyImage
          }
        }
        newsText {
          raw
        }
      }
    }
    allNewsPost_zh: allContentfulNewsPost(
      sort: { fields: postDate, order: DESC }
      limit: 3
      filter: { node_locale: { eq: "zh-CN" } }
    ) {
      nodes {
        slug
        title
        postDate(formatString: "YYYY-M-DD")
      }
    }
    teamPage_zh: allContentfulTeamPage(
      filter: { node_locale: { eq: "zh-CN" } }
    ) {
      nodes {
        teamMembers {
          name
          jobTitle
        }
      }
    }
  }
`
