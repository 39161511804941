import React from 'react'
// import BgLink from './bg-link'
import * as styles from './styles.module.scss'
import { LocalizedLink } from 'gatsby-theme-i18n'
// import { tr } from 'js/translations.js'
// import { useLocalization } from 'gatsby-theme-i18n'

import Inview from 'components/Inview'

const NewsLink = ({ post }) => {
  // const { locale } = useLocalization()
  return (
    <div className={styles.newsLink}>
      <Inview>
        <div className='fade-in-up'>
          <LocalizedLink to={`/news/${post.slug}/`}>
            <h3>{post.title}</h3>
            <h4>{post.postDate}</h4>
          </LocalizedLink>
          {/* <BgLink
          to={`/news/${post.slug}/`}
          text={`${tr('READ_MORE', locale)} »`}
        /> */}
        </div>
      </Inview>
    </div>
  )
}

export default NewsLink
