import React from 'react'
import * as styles from './styles.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import BgLink from 'components/BgLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import TeamList from 'components/TeamList'

const Component = ({
  text,
  teamMembers,
  image,
  link,
  isReversed,
  isContain,
  aspectRatio,
}) => {
  const { locale } = useLocalization()
  return (
    <Inview>
      <div
        className={`${styles.textImage} 
        ${isReversed ? styles.isReversed : ''} 
        ${teamMembers ? styles.hasTeamMembers : ''} 
        fade-in grid grid-13`}
      >
        <div className={styles.text}>
          <div className='inner'>
            <div className='fade-in-up '>{text}</div>
            {teamMembers && (
              <TeamList teamMembers={teamMembers} isHome={true} />
            )}
            <div className='fade-in-up '>
              {link && (
                <BgLink
                  to={link.to}
                  text={`${link.text ? link.text : tr('READ_MORE', locale)} »`}
                />
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.image} ${isContain ? styles.contain : ''}`}>
          <ImageWrap image={image} />
        </div>
      </div>
    </Inview>
  )
}

export default Component
